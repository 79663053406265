class Application {
  isDialogActive: boolean;

  constructor() {
    this.isDialogActive = false;
  }
}

const application = new Application();

export default application as Application;